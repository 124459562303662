import { Box, Grid, Checkbox } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { insertUser, setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";
import { call } from '../../../store/api';
import { showNotification } from 'admin/store/actions/snackbar';
import { PhoneMask } from 'admin/components/Controls/InputMasked';
import { validateEmail, validateNoSpecialCharacters, validateNumbersOnly, validateRequired, validateURL } from 'admin/common/utils';
import { ADMIN_WEB_CACHE } from "admin/constant";
import { updateWebAdminCachePractice } from "admin/store/actions";
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";
const initialFValues = {
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    primaryPhone: "",
    mobilePhone: "",
    email: "",
    npi: "",
    ehrUsername: "",
    levelId: "",
    practiceRole: "",
    userType: "",
    manualCreated: true,
    qualityiqUrl: ""
}

const trimFields = (values: any) => {
    values.primaryPhone = values.primaryPhone.replace(/\D/g, '')
    values.mobilePhone = values.mobilePhone.replace(/\D/g, '')

    return values
}
const AddUserForm = forwardRef(({ onFormValueChanged }: any, ref) => {
    const [isAutoInvite, setAutoInvite] = useState(true);
    const [isInputDisabled, setInputDisabled] = useState(false);
    const emailDataObj: any = {}
    const [existingUserEmailObj, setExistingUserEmailObj] = useState(emailDataObj);
    const classes = useStyles();
    const intl = useIntl()
    const dispatch = useDispatch()
    let isSaveAndClose = false
    let isPracticeUser = false
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    const { adminWebCachePractice } = admin
    let usersLevels = admin.userLevels && admin.userLevels.filter((u: any) => u.type !== "PATIENT").map((ul: any) => {
        return { ...ul, title: ul.description }
    });

    if (admin.configurePracticeId) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "PROVIDER")
        isPracticeUser = true
    } else if (auth.currentOrg?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "ADMIN")
        if (auth.currentOrg?.parent) {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') === -1)
        } else {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') !== -1)
        }
    } else if (auth.currentPractice?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "PROVIDER")
        isPracticeUser = true
    }

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any, saveClose: boolean) {
                return handleSubmit(e, saveClose);
            },
            saveAndContinueLater(index: number) {
                return saveAndContinue(index);
            }
        }),
    )
    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('firstName' in fieldValues) {
            err.firstName = validateRequired(fieldValues.firstName, intl)
            if (err.firstName === "") err.firstName = validateNoSpecialCharacters(fieldValues.firstName, intl)
        }
        if ('middleName' in fieldValues) {
            err.middleName = validateNoSpecialCharacters(fieldValues.middleName, intl)
        }
        if ('lastName' in fieldValues) {
            err.lastName = validateRequired(fieldValues.lastName, intl)
            if (err.lastName === "") err.lastName = validateNoSpecialCharacters(fieldValues.lastName, intl)
        }

        if ('email' in fieldValues) {
            err.email = validateRequired(fieldValues.email, intl)
            if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
        }
        if ('mobilePhone' in fieldValues) {
            err.mobilePhone = isPracticeUser ? "" : validateRequired(fieldValues.mobilePhone?.replace(/[_-]/g, ''), intl)
        }
        if ('levelId' in fieldValues)
            err.levelId = validateRequired(fieldValues.levelId, intl)

        if ('npi' in fieldValues) {
            err.npi = validateNumbersOnly(fieldValues.npi, intl)
        }
        if ('qualityiqUrl' in fieldValues) {
            err.qualityiqUrl = validateURL(fieldValues.qualityiqUrl, intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        resetValues,
        trimValues
    } = useForm(initialFValues, false, validate);

    if (values && !values.levelId) {
        const orgAdmin1 = usersLevels && usersLevels.find((u: any) => u.name === "ORG_ADMIN_1")
        if (orgAdmin1) {
            values.levelId = orgAdmin1?.id
        }
    }

    useEffect(() => {
        if (admin.configurePracticeId) {
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData?.addUserDetails
            if (tempFormData) {
                values.firstName = tempFormData?.firstName
                values.primaryPhone = tempFormData?.primaryPhone
                values.mobilePhone = tempFormData?.mobilePhone
                values.prefix = tempFormData?.prefix
                values.middleName = tempFormData?.middleName
                values.lastName = tempFormData?.lastName
                values.email = tempFormData?.email
                resetValues(values)
                if (tempFormData?.email) {
                    handleInputBlur({ target: { value: tempFormData?.email } })
                }
            }
        }
    }, [admin.configurePracticeId])

    const handleSubmit = (e: any, saveAndClose: boolean) => {
        isSaveAndClose = saveAndClose
        if (isInputDisabled) {
            values['primaryPhone'] = existingUserEmailObj.primaryPhone
            values['mobilePhone'] = existingUserEmailObj.mobilePhone
            values['prefix'] = existingUserEmailObj.prefix
            values['firstName'] = existingUserEmailObj.firstName
            values['middleName'] = existingUserEmailObj.middleName
            values['lastName'] = existingUserEmailObj.lastName
        }
        trimValues()
        e.preventDefault()
        if (validate()) {
            const user = admin.userLevels && admin.userLevels.find((ul: any) => ul.id === values.levelId);
            values['userType'] = user && user.type
            values['isAutoInvite'] = isAutoInvite
            values.primaryPhone = values.primaryPhone?.replace(/[_-]/g, '')?.trim()
            values.mobilePhone = values.mobilePhone?.replace(/[_-]/g, '')?.trim()
            dispatch(insertUser(trimFields(values), onAddUser))
            return false
        }
        dispatch(showNotification("Invalid", "error", 'Please fill the required fields'))
        return false
    }
    const onAddUser = (success: boolean) => {
        if (success) {
            resetForm()
        }
        if (success && isSaveAndClose) {
            dispatch(setActiveModal(""));
        }
    }
    const onAutoInviteCheck = (e: any) => {
        setAutoInvite(e.target.checked)
    }
    onFormValueChanged(JSON.stringify(initialFValues) !== JSON.stringify(values))

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            tempFormData: {
                name: admin.configurePracticeName,
                parentOrgName: auth.currentOrg?.name,
                configurePractice: true,
                addUserDetails: {
                    primaryPhone: values.primaryPhone,
                    mobilePhone: values.mobilePhone,
                    prefix: values.prefix,
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    levelId: values.levelId,
                    email: values.email
                },
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }
    const handleInputBlur = async (event: any) => {
        const { value } = event.target
        if (auth && value.trim() !== "") {
            const existingAdminEmailURL = "/admin/v1/admin/email/" + value;
            let existingEmailDetails = await call("GET", existingAdminEmailURL, {})
                .catch(error => { });
            if (!existingEmailDetails) {
                if (auth.currentPractice?.id) {
                    const existingProviderEmailURL = "/admin/v1/provider/email/" + value;
                    existingEmailDetails = await call("GET", existingProviderEmailURL, {}, { "x-application-practiceid": auth.currentPractice?.id })
                        .catch(error => { });
                }
            }

            if (existingEmailDetails && existingEmailDetails.email !== null) {
                setInputDisabled(true)
                setExistingUserEmailObj(existingEmailDetails)
            } else {
                setInputDisabled(false)
                setExistingUserEmailObj({})
            }
        } else {
            setInputDisabled(false)
            setExistingUserEmailObj({})
        }
    }
    return (
        <Form onSubmit={handleSubmit}>
            {false && <Box className={classes.autoInviteText}>
                <Checkbox color="primary" disableRipple disableFocusRipple
                    checked={isAutoInvite} onChange={onAutoInviteCheck} id="chkAddUserAutoInvite" />
                {intl.formatMessage({ id: "AddUserForm.SendAutoinvite.Text" })}
            </Box>}
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "AddUserForm.Heading.Contact" })}
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Controls.Input
                        name="email"
                        label="Email *"
                        value={values.email}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        error={errors.email}
                        maxLength={255}
                        placeholder="Enter Here"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controls.Input
                        name="primaryPhone"
                        label="Primary Phone"
                        value={values.primaryPhone}
                        placeholder="(###) ### - ####"
                        onChange={handleInputChange}
                        error={errors.primaryPhone}
                        maxLength={255}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controls.Input
                        name="mobilePhone"
                        label={`Mobile Phone ${isPracticeUser ? "" : "*"}`}
                        value={values.mobilePhone}
                        placeholder="(###) ### - ####"
                        onChange={handleInputChange}
                        error={errors.mobilePhone}
                        maxLength={255}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
            </Grid>
            {isInputDisabled ?
                <Box mb={1} className={classes.userExistsMsg}>
                    {intl.formatMessage({ id: "AddUserForm.UserAlreadyExist.Text" })}
                </Box> : " "
            }
            <Box mt={2} mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "AddUserForm.Heading.Personal" })}
            </Box>
            <Grid container>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Select
                            name="prefix"
                            label="Prefix"
                            value={values.prefix}
                            onChange={handleInputChange}
                            options={admin.prefixes}
                            error={errors.prefix}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="firstName"
                            label="First Name *"
                            value={values.firstName}
                            onChange={handleInputChange}
                            error={errors.firstName}
                            maxLength={50}
                            placeholder="Enter Here"
                            disabled={isInputDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="middleName"
                            label="Middle Name"
                            value={values.middleName}
                            onChange={handleInputChange}
                            error={errors.middleName}
                            maxLength={50}
                            placeholder="Enter Here"
                            disabled={isInputDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="lastName"
                            label="Last Name *"
                            value={values.lastName}
                            onChange={handleInputChange}
                            error={errors.lastName}
                            maxLength={50}
                            disabled={isInputDisabled}
                            placeholder="Enter Here"
                        />

                    </Grid>
                </Grid>
                <Box mt={2} mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "AddUserForm.Heading.Professional" })}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Select
                            name="levelId"
                            label="User Level *"
                            value={values.levelId}
                            onChange={handleInputChange}
                            options={usersLevels}
                            error={errors.levelId}
                            placeholder="Enter Here"
                            altContent={usersLevels ? <UserLevelDetailsMenu userLevels={usersLevels} /> : null}
                        />
                    </Grid>
                    {isPracticeUser &&
                        <>
                            <Grid item xs={3}>
                                <Controls.Input
                                    name="npi"
                                    label="NPI"
                                    value={values.npi}
                                    onChange={handleInputChange}
                                    error={errors.npi}
                                    maxLength={10}
                                    placeholder="Enter Here" />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    name="ehrUsername"
                                    label="EMR User Name"
                                    value={values.ehrUsername}
                                    onChange={handleInputChange}
                                    error={errors.ehrUsername}
                                    maxLength={255}
                                    placeholder="Enter Here" />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    name="qualityiqUrl"
                                    label="QualityIQ URL"
                                    value={values.qualityiqUrl}
                                    onChange={handleInputChange}
                                    error={errors.qualityiqUrl}
                                    maxLength={255}
                                    placeholder="Enter Here" />
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Form>
    )
})
export default AddUserForm