import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { nonAuthCall } from "admin/store/api";
import { AppReduxStore } from "../reducerTypes";
import { setError } from "store/actions/error";
import { REGISTRATION_STEP, SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT, SESSION_STORAGE_REGISTRATION_ID, USER_EXISTS_ERROR, USER_TYPE, VERIFICATION_TYPE } from "constant";
import { push } from 'connected-react-router';
import { getProfiles } from 'store/actions';
import { createGUIDString } from 'admin/common/utils';
import { hideLoading, showLoading } from './loading';
import { call } from 'store/api';
import { renewAppTokens } from 'authentication/appAuth';

export const setRegistrationValues = (payload: any) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_REGISTRATION_VALUES', payload })
    }
}

export const setRegistrationStep = (registrationStep: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_REGISTRATION_STEP', payload: { registrationStep } })
    }
}

export const setRegistrationToken = (regToken: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_REGISTRATION_TOKEN', payload: { regToken } })
    }
}

export const setFeedbackLoop = (feedbackLoop: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return await call("POST", "/admin/v1/registration/feedback-loop", feedbackLoop).then((payload) => {
            dispatch({ type: 'SET_FEEDBACK_LOOP', payload: { feedbackLoop: feedbackLoop } })
        }).catch(e => {
            dispatch(setError("", e.message))
        });
    }
}

export const verifyIdentity = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken, firstName, lastName, phoneNumber } = getState().registration
        dispatch({ type: 'VERIFY_IDENTITY' })
        let identityValid = false
        try {
            // v1: /admin/v1/registration/validateProviderIdentity
            const validIdentity = await nonAuthCall("POST", "/registration/v2/admin/validateIdentity", {
                firstName,
                lastName,
                phoneNumber: phoneNumber.replace(/\D/g, ''),
                regToken
            })
            identityValid = validIdentity?.isValid
            if (identityValid) { //TODO: V2- check otp code required also(validIdentity?.otpCodeRequired)
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION))
            } else {
                dispatch(setError("Registration.error.invalidIdentity", "", "Registration.error.desc.invalidIdentity"))
            }
        } catch (e: any) {
            dispatch(setError("", e.message, "Registration.error.desc.invalidToken"))
        }
        dispatch({ type: 'VERIFY_IDENTITY_COMPLETE', payload: { identityValid } })
    }
}


export const verifyProviderIdentity = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken, firstName, lastName, practiceSecret } = getState().registration
        dispatch({ type: 'VERIFY_PDENTITY' })
        let identityValid = false
        try {
            // v1: /admin/v1/registration/validateProviderIdentity
            const validIdentity = await nonAuthCall("POST", "/registration/v2/practice/validateIdentity", {
                firstName,
                lastName,
                practiceSecret: btoa(practiceSecret),
                regToken
            })
            identityValid = validIdentity?.isValid
            if (identityValid) {
                dispatch(setRegistrationValues({ registrationId: validIdentity?.registrationId }))
                sessionStorage.setItem(SESSION_STORAGE_REGISTRATION_ID, validIdentity?.registrationId)
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROREGISTARTION.LOG_IN_OR_CREATE))
            } else {
                dispatch(setError("Registration.error.invalidIdentity", "", "Registration.error.desc.invalidIdentity"))
            }
        } catch (e: any) {
            dispatch(setError("", e.message, "Registration.error.desc.invalidToken"))
        }
        dispatch({ type: 'VERIFY_IDENTITY_COMPLETE', payload: { identityValid } })
    }
}

export const verifyPatientIdentity = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken, firstName, lastName, dateOfBirth } = getState().registration
        dispatch({ type: 'VERIFY_IDENTITY' })
        let identityValid = false
        try {
            // v1: /admin/v1/registration/validateIdentity
            const validIdentity = await nonAuthCall("POST", "/registration/v2/patient/validateIdentity", {
                firstName,
                lastName,
                dateOfBirth,
                regToken
            })
            identityValid = validIdentity?.isValid
            if (identityValid) {
                dispatch(setRegistrationValues({ registrationId: validIdentity?.registrationId }))
                sessionStorage.setItem(SESSION_STORAGE_REGISTRATION_ID, validIdentity?.registrationId)
                dispatch(setRegistrationStep(REGISTRATION_STEP.PATIENT.ACCEPT_TOS))
            } else {
                dispatch(setError("Registration.error.invalidIdentity", "", "Registration.error.desc.invalidIdentity"))
            }
        } catch (e: any) {
            dispatch(setError("", e.message, "PatientRegistration.error.desc.invalidToken"))
        }
        dispatch({ type: 'VERIFY_IDENTITY_COMPLETE', payload: { identityValid } })

    }
}

export const acceptTOS = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'ACCEPT_TOC', payload: {} })
    }
}

export const verifyOTPFactor = (oktaAuth?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
        const { regToken, otpCode, emailVerified, profileRoleJoinId } = getState().registration
        dispatch({ type: 'VERIFY_OTP_FACTOR' })
        let otpValid = true
        try {
            //v1: /admin/v1/registration/verifyOTPFactor
            const resultOTPVerify = await nonAuthCall("POST", "/registration/v2/verifyOTPFactor", {
                otpCode,
                verificationType: emailVerified ? VERIFICATION_TYPE.EMAIL : VERIFICATION_TYPE.SMS,
                regToken: regToken ?? ""
            })
            otpValid = resultOTPVerify?.isValid
            if (otpValid) {
                if (profileRoleJoinId?.length > 0) {
                    dispatch(setRegistrationValues({ registrationId: resultOTPVerify?.registrationId }))
                    dispatch(completeRegistrationPostLogin(oktaAuth))
                } else {
                    dispatch(setRegistrationValues({ registrationId: resultOTPVerify?.registrationId }))
                    sessionStorage.setItem(SESSION_STORAGE_REGISTRATION_ID, resultOTPVerify?.registrationId)
                }

            }
        } catch (e: any) {
            dispatch(setError("Registration.error.invalidOTP", "", e?.message))
            otpValid = false
        }
        if (!profileRoleJoinId) {
            dispatch({ type: 'VERIFY_OTP_FACTOR_COMPLETE', payload: { otpValid } })
        }
    }
}

export const resendOTP = (cb: () => void) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken, emailVerified } = getState().registration
        const otpType = emailVerified ? VERIFICATION_TYPE.EMAIL : VERIFICATION_TYPE.SMS
        try {
            await nonAuthCall("GET", `/admin/v1/registration/resendOTP/${regToken}/${otpType}`)
            cb()
        } catch (e: any) {
            dispatch(setError("Registration.error.resendOTP"))
        }
    }
}

export const updateUsername = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { email, registrationId } = getState().registration
        try {
            // v1: /admin/v1/registration/updateUsername
            const { isNewAccount, requireOTP } = await nonAuthCall("POST", "/registration/v2/updateUsername", {
                acceptDataConsent: true,
                email,
                registrationId
            })
            //console.log("req", requireOTP)
            dispatch(setRegistrationValues({ isNewAccount }))
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { emailVerified: true } })
            if (requireOTP) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION))
            }
            else if (isNewAccount) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.CREATE_PROFILE))
            }
            else {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.EXISTING_USER_LOGIN))
            }
        } catch (e: any) {
            if (e.message === USER_EXISTS_ERROR) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.EXISTING_USER_LOGIN))
            } else {
                dispatch(setError("Registration.error.updateUsername"))
            }
        }
    }
}

export const updateUsernameProvider = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { email, registrationId } = getState().registration
        try {
            // v1: /admin/v1/registration/updateUsername
            const { isNewAccount } = await nonAuthCall("POST", "/registration/v2/updateUsername", {
                acceptDataConsent: true,
                email,
                registrationId
            })
            dispatch(setRegistrationValues({ isNewAccount }))
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { emailVerified: true } })
            if (isNewAccount) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROREGISTARTION.CREATE_PROFILE))
            }
            else {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROREGISTARTION.EXISTING_USER_LOGIN))
            }
        } catch (e: any) {
            if (e.message === USER_EXISTS_ERROR) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROREGISTARTION.EXISTING_USER_LOGIN))
            } else {
                dispatch(setError("Registration.error.updateUsername"))
            }
        }
    }
}

// Patient - add email
// This will either redirect to OPT view, or return error for already associated account
export const updateUsernamePatient = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { email, registrationId } = getState().registration
        try {
            // v1: /admin/v1/registration/updateUsername
            const { requireOTP, isNewAccount } = await nonAuthCall("POST", "/registration/v2/updateUsername", {
                acceptDataConsent: true,
                email,
                registrationId
            })
            dispatch(setRegistrationValues({ isNewAccount }))
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { emailVerified: true } })
            if (requireOTP) {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PATIENT.OTP_CONFIRMATION_PATIENT))
            } else {
                dispatch(setRegistrationStep(REGISTRATION_STEP.PATIENT.SET_PASSWORD))
            }
        } catch (e: any) {
            if (e.status === 400 && e.message.includes("username already exists")) {
                // display custom warning message in view for this case
                dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { error: e } })
            } else {
                // reset view to clear any previous error passed to it, if any
                dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { error: null } })
                // display error using the global snackbar module
                dispatch(setError("Registration.error.invalidIdentity", "", e.message))
            }
        }
    }
}

export const addSecretQuestion = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { passwordVerified: true } })
    }
}

export const completeRegistration = (oktaAuth?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { password, registrationId, isNewAccount, recoveryQuestion, recoveryAnswer } = getState().registration
        const encodePassword = btoa(password)
        const guid = createGUIDString()
        try {
            if (oktaAuth) {
                dispatch(showLoading(guid, "Loading Profiles", ""))
            }
            // v1: /admin/v1/registration/completeRegistration

            const regId = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_ID)
            isNewAccount ? await nonAuthCall("POST", "/registration/v2/completeRegistration", {
                password: encodePassword,
                recoveryQuestion,
                recoveryAnswer,
                registrationId
            }) : await call("POST", "/registration/v2/completeRegistration", {
                registrationId: regId,
            })
            if (oktaAuth) {
                await oktaAuth.token.renewTokens()
                    .then(function (res: any) {
                        oktaAuth.tokenManager.setTokens(res);
                        sessionStorage.setItem(SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT, "YES")
                    })
                    .catch(function (err: any) {
                    });

                setTimeout(function () {
                    dispatch(getProfiles())
                    dispatch(hideLoading(guid))
                }, 2000)
            }

            await sessionStorage.removeItem(SESSION_STORAGE_REGISTRATION_ID)
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { complete: true } })
        } catch (e: any) {
            dispatch(setError("Registration.error.updateUsername", e.message))
            dispatch(hideLoading(guid))
        }
    }
}

// Patient - set password then direct to the verify-info step
export const completeRegistrationPatient = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { password } = getState().registration
        try {
            const regId = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_ID)
            // v1: /admin/v1/registration/completeRegistration
            await nonAuthCall("POST", "/registration/v2/completeRegistration", {
                password,
                registrationId: regId
            })
            sessionStorage.removeItem(SESSION_STORAGE_REGISTRATION_ID)
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { complete: true } })
        } catch (e: any) {
            dispatch(setError("Registration.error.setPassword", "", e.message))
            // in case the patient already completed registration
            // e.g. using valid token to re-register & trying to update email
            if (e.message.includes("Cannot create new user") && e.message.includes("already exists")) {
                dispatch(push('/patient/download-app'))
            }
        }
    }
}

// This is called after patient registration complete and patient login
// TODO: should it call asscociate account too?
export const fetchUserInfo = (regToken: string | undefined) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { userInfo: {} } })
        if (regToken) {
            try {
                // /admin/v1/registration/user/${regToken}
                const userData = await call("GET", `/admin/v1/user/current`)
                const userInfo = userData?.user?.profileRoleJoinInfos.find((p: any) => p.id === userData.user.currentProfileRoleJoinId)?.patientProfile
                dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { userInfo: userInfo } })
            } catch (e: any) {
                dispatch(setError("PatientRegistration.error.fetchUser", "", e.message))
            }
        } else {
            dispatch(setError("PatientRegistration.error.fetchUser"))
        }
    }
}

// This is called during provider login// Not In use anymore
export const associateAccount = (username: string, password: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken } = getState().registration
        try {
            await call("POST", "/admin/v1/registration/associateAccount", {
                username,
                password,
                regToken
            })
        } catch (e: any) {
            dispatch(setError("Registration.error.associateAccount", e.message))
        }
    }
}

export const setProfileDetailsVerify = (profileRoleJoinId: string, userType: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_ADDITIONAL_PROFILE_DETAILS', payload: { profileRoleJoinId, userType } })
    }
}

export const verifyProviderIdentityPostLogin = (oktaAuth: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
        const { regToken, firstName, lastName, practiceSecret, profileRoleJoinId } = getState().registration
        dispatch({ type: 'VERIFY_PDENTITY' })
        let identityValid = false
        try {
            const validIdentity = await call("POST", "/registration/v2/practice/postlogin/validateIdentity", {
                dateOfBirth: "",
                firstName,
                lastName,
                phoneNumber: "",
                practiceSecret: btoa(practiceSecret),
                regToken,
                relationToPatient: "",
                profileRoleJoinId
            })
            identityValid = validIdentity?.isValid
            if (identityValid) {
                dispatch(setRegistrationValues({ registrationId: validIdentity?.registrationId }))
                if (profileRoleJoinId?.length > 0) {
                    dispatch(completeRegistrationPostLogin(oktaAuth))
                }
            } else {
                dispatch(setError("Registration.error.invalidIdentity", "", "Registration.error.desc.invalidIdentity"))
            }
        } catch (e: any) {
            dispatch(setError("", e.message, "Registration.error.desc.invalidToken"))
        }
        dispatch({ type: 'VERIFY_IDENTITY_COMPLETE', payload: { identityValid } })
    }
}

export const verifyAdminIdentityPostLogin = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { regToken, firstName, lastName, phoneNumber, profileRoleJoinId } = getState().registration
        dispatch({ type: 'VERIFY_IDENTITY' })
        let identityValid = false
        try {
            const validIdentity = await call("POST", "/registration/v2/admin/postlogin/validateIdentity", {
                dateOfBirth: "",
                firstName,
                lastName,
                phoneNumber: phoneNumber.replace(/\D/g, ''),
                practiceSecret: "",
                regToken,
                profileRoleJoinId,
                relationToPatient: ""
            })
            identityValid = validIdentity?.isValid
            if (identityValid) { //TODO: V2- check otp code required also(validIdentity?.otpCodeRequired)
                dispatch(setRegistrationToken(validIdentity?.regToken))
                dispatch(setRegistrationStep(REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION))
            } else {
                dispatch(setError("Registration.error.invalidIdentity", "", "Registration.error.desc.invalidIdentity"))
            }
        } catch (e: any) {
            dispatch(setError("", e.message, "Registration.error.desc.invalidToken"))
        }
        dispatch({ type: 'VERIFY_IDENTITY_COMPLETE', payload: { identityValid } })
    }
}

export const completeRegistrationPostLogin = (oktaAuth?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { registrationId, profileRoleJoinId, userType } = getState().registration
        try {
            await call("POST", "/registration/v2/postlogin/completeRegistration", {
                registrationId: registrationId,
            })
            let url = "/provider/home"
            await call("PATCH", `/admin/v1/user/current-profile/${profileRoleJoinId}`)
            switch (userType) {
                case USER_TYPE.ADMIN:
                    url = "/admin/home"
                    break
                case USER_TYPE.PROVIDER:
                    url = "/provider/home"
                    break
            }
            await renewAppTokens();
            if (url) {
                window.location.replace(url)
            }
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { complete: true } })
        } catch (e: any) {
            dispatch(setError("Registration.error.updateUsername", e.message))
        }
    }
}

export const completeRegistrationPostLoginMethod = (oktaAuth?: any, userRoleId?: any, userType?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            /*
            await call("POST", "/registration/v2/postlogin/completeRegistration", {
                userRoleId
            }) */
            let url = "/provider/home"
            await call("PATCH", `/admin/v1/user/current-profile/${userRoleId}`)
            switch (userType) {
                case USER_TYPE.ADMIN:
                    url = "/admin/home"
                    break
                case USER_TYPE.PROVIDER:
                    url = "/provider/home"
                    break
            }
            await renewAppTokens();
            if (url) {
                window.location.replace(url)
            }
            dispatch({ type: 'SET_REGISTRATION_VALUES', payload: { complete: true } })
        } catch (e: any) {
            dispatch(setError("Registration.error.updateUsername", e.message))
        }
    }
}